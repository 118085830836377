import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import logo from './assets/deluxe_logo_2020.png';
import logoRetina from './assets/deluxe_logo_2020@2x.png';
import './App.css';

function App() {
  const apiUrl   = process.env.REACT_APP_API_URL     || '';
  const eSignUrl = process.env.REACT_APP_E_SIGN_URL  || '';
  const cookies  = new Cookies();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isLogin, setLogin] = useState(true);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const storedEmail = cookies.get('email');

    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }
  }, [setEmail, setRememberMe]);

  const getAuthToken = async () => {
    const response = await fetch(`${apiUrl}?authToken`, {
      method: 'POST',
      headers: {
        Authorization: 'Basic api_key',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password: password })
    });

    if (response.ok) return await response.json();

    if (response.status === 401) {
      throw new Error('Incorrect email address or password');
    }
  };

  const findSenderID = async () => {
    const response = await fetch(`${apiUrl}?findSender`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    });

    if (!response.ok) throw await response.json();
    if (response.ok) return await response.json();
  };

  const sendResetPassEmail = async (senderId) => {
    const response = await fetch(`${apiUrl}?resetPass`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ senderId: senderId }),
    });

    if (!response.ok) throw await response.json();
    if (response.ok) return await response.json().catch((e) => e);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError('');
    try {
      if (isLogin) {
        const { value } = await getAuthToken();

        rememberMe ? cookies.set('email', email) : cookies.remove('email');

        window.location = `${eSignUrl}/auth?authenticationToken=${value}&target=${eSignUrl}/a/dashboard`;
      } else {
        const { count, results } = await findSenderID();

        if (count === 1) {
          await sendResetPassEmail(results[0].id);
          setLogin(true);
          setError('');
        } else if (count === 0) {
          throw new Error('User not found');
        }
      }
    } catch (error) {
      if (error && error.message) {
        setError(error.message);
      }
    }

    setSubmitting(false);
  };

  return (
    <div className='login-form'>
      <form onSubmit={submitHandler} autoComplete='off'>
        <img
          src={logo}
          srcSet={`${logoRetina} 2x`}
          alt='deluxe'
          className='login-form-logo'
        />

        <p className='login-form-text'>
          {isLogin ? 'Log in to Deluxe eSignature' : 'Reset your password'}
        </p>

        {isLogin ? (
          <>
            <div className='login-form-input'>
              <input
                type='text'
                placeholder='Email Address'
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  setError('');
                }}
                disabled={submitting}
                autoComplete='off'
              />{' '}
              <i className='icon-email' />
            </div>

            <div className='login-form-input'>
              <input
                type='password'
                placeholder='Password'
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError('');
                }}
                disabled={submitting}
                autoComplete='off'
              />
              <i className='icon-password' />
            </div>
          </>
        ) : (
          <>
            <div className='login-form-input'>
              <input
                type='text'
                placeholder='Username or email address'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={submitting}
              />{' '}
              <i className='icon-email' />
            </div>
            <p className='login-form-note'>
              Password reset instructions will be sent to your registered email
              address.
            </p>
          </>
        )}

        <div className='login-form-additions'>
          <div>
            {isLogin && (
              <>
                <input
                  type='checkbox'
                  className='login-form-remember'
                  id='remember-me'
                  disabled={submitting}
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label
                  htmlFor='remember-me'
                  className='login-form-remember-label'>
                  Remember my username
                </label>
              </>
            )}
          </div>

          <div>
            <a
              href='/#'
              className='login-form-forgot'
              onClick={(event) => {
                event.preventDefault();
                setLogin(!isLogin);
                setError('');
              }}>
              {isLogin ? 'Forgot your password?' : 'Sign in'}
            </a>
          </div>
        </div>

        <button
          type='submit'
          className='login-form-submit'
          disabled={submitting}>
          {isLogin ? 'LOG IN' : 'SUBMIT'}

          <i
            className='fa fa-refresh fa-spin'
            style={{ opacity: submitting ? 1 : 0 }}></i>
        </button>
        {error && <p className='login-form-error'>{error}</p>}
      </form>
    </div>
  );
}

export default App;
